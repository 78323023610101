import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import shineTTS from "../../Assets/Projects/c-shine-tts.png";
import r from "../../Assets/Projects/c-clock.png";
import notions from "../../Assets/Projects/c-notion.png";
import dapeng from "../../Assets/Projects/c-dapeng-soa.png";
import resonance from "../../Assets/Projects/c-resonance.png";
import md from "../../Assets/Projects/c-md2all.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Personal <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shineTTS}
              isBlog={false}
              title="ShineTTS"
              description="A smart text-to-speech (TTS) web tool with the feature of downloading generated audio as MP3."
              ghLink="https://github.com/leihuazhe/shine-crafts"
              demoLink="https://tts.317318.xyz/tts"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dapeng}
              isBlog={false}
              title="dapeng-soa"
              description="Dapeng-soa is a lightweight, high performance micro-service framework, which is based on netty and thrift. It provides service metadata generated from the thrift IDL automatically."
              ghLink="https://github.com/dapeng-soa/dapeng-soa"
              demoLink="https://dapeng-soa.github.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={resonance}
              isBlog={true}
              title="Resonance"
              description="A blog about to connecting Thoughts and Emotions."
              demoLink="https://resonance-eight.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={notions}
              isBlog={true}
              title="Notion Blog"
              description="A pesonal blog which use the notion note as Data."
              demoLink="https://notion.317318.xyz/about"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={md}
              isBlog={false}
              title="MD2All"
              description="A markdown editor."
              demoLink="https://md.317318.xyz/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={r}
              isBlog={false}
              title="R Clock"
              description="A beautiful clock."
              ghLink="https://github.com/leihuazhe/shine-crafts"
              demoLink="https://r.317318.xyz/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
