import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.svg";
// import myImg from "../../Assets/avataaars.png";
import myImg from "../../Assets/me.jpg";
import Image from 'react-bootstrap/Image';

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I'm a Senior Software Engineer with 9 years of experience, focusing on crafting robust backend systems.
            <br />
            <br />
            Currently, I’m working at Sensors Data Company in 
            <i>
                <b className="purple"> Wuhan City, China. </b>
              </i>
            {/* I leverage my expertise to ensure our applications run smoothly and efficiently. */}
            <br />
            <br />
            My career began in 2015 as an intern at an Auto E-commerce Company. The hard work I invested during that internship ignited my passion for coding...
              <br />
              <br />I am proficient in
              <i>
                <b className="purple"> Java, Scala, and their ecosystems. </b>
              </i>
              My real strength lies in understanding business needs and delivering high-performance backend solutions that drive engagement and efficiency. 
              I have extensive experience in both 
              <i>
                <b className="purple"> B2B </b> and <b className="purple"> B2C </b>
                development.
              </i>
              <br />
              <br />
              I am seeking relocation and international opportunities, open to all kinds of 
              <i>
                <b className="purple"> backend software development </b>
              </i>
              as well as some 
              frontend development 
              <i>
                <b className="purple"> frontend development </b>
              </i>
              roles.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              {/* <img src={myImg} className="img-fluid roundedCircle" alt="avatar" /> */}
              <Image fluidroundedCircle src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/leihuazhe"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/youjier"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/huazhe-lei/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
